import {
  Button,
  DateTimePickerControl,
  Drawer,
  Field,
  FieldError,
  Modal,
  SelectControl,
  TextControl,
} from "components";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { LoadingIcon } from "resources/icons";
import { FormCommonPropsType } from "../../../context";
import { SUBMIT_FUNC_TYPE, useDayOffForm } from "./useDayOffForm";
import { customDateValidator, validator } from "./validators/validators";
import { FormHelperText } from "@mui/material";
import { useAuthContext } from "contexts";
import {calculateRemainingDays} from "./utils";

const MODAL_MESSAGE = {
  [SUBMIT_FUNC_TYPE.DELETE]: "Delete your planned absence.",
  [SUBMIT_FUNC_TYPE.SAVE]: "Send your planned absence for manager review.",
};
export const DayOffForm: FC<FormCommonPropsType> = ({
  formData,
  handleModalClose,
}) => {
  const {
    borderDate,
    handleDeleteTimesheet,
    shouldDisableDate,
    handleSubmit,
    submitFuncType,
    setSubmitFuncType,
    modalRef,
    initialValues,
    isDeleteTimeSheetLoading,
    isUpsertDayOffLoading,
    isEditForm,
    timesheetsLogsTypes,
    exceptionDaysData,
    remainingDays,
  } = useDayOffForm({ formData, handleModalClose });

  const { isManager } = useAuthContext();

  return (
    <Drawer
      isOpen
      onClose={handleModalClose}
      title={`${isManager() ? "Fill employee" : isEditForm ? "Editing" : "Planning"} absence`}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize
        validate={validator}
      >
        {({
          handleSubmit,
          submitting,
          pristine,
          hasValidationErrors,
          submitError,
          values,
        }) => {
          const submitFunc = {
            [SUBMIT_FUNC_TYPE.DELETE]: handleDeleteTimesheet,
            [SUBMIT_FUNC_TYPE.SAVE]: handleSubmit,
          };

          const dayOffLimit = remainingDays?.filter(item => {
            return item?.logType === values.logType
          })
          const currentYear = new Date().getFullYear()
          const currentYearLimit = dayOffLimit?.find(item => {
            return item?.year === currentYear
          })
          const nextYearLimit = dayOffLimit?.find(item => {
            return item?.year === currentYear + 1
          })
          const logTypeLabel = timesheetsLogsTypes.find(
            item => item?.value === values.logType
          )?.label

          const currentYearRemainingDays = currentYearLimit && calculateRemainingDays(currentYearLimit);

          let nextYearRemainingDays = currentYearLimit && nextYearLimit && calculateRemainingDays(nextYearLimit);

          // we don't want to show remaining days from current year added to next year limit
          if (nextYearRemainingDays && currentYearLimit?.limit) {
            nextYearRemainingDays -= currentYearLimit.limit;
          }

          return (
            <form className="flex h-full flex-col justify-between">
              <div>
                {Boolean(submitError) && <FieldError>{submitError}</FieldError>}
                <Field
                  name="logType"
                  label="Type *"
                  component={SelectControl}
                  options={timesheetsLogsTypes}
                  dataCy="day-off-type"
                />{
                currentYearLimit && currentYearRemainingDays !== undefined && (
                  <FormHelperText>
                    Remaining {logTypeLabel} days{nextYearLimit ? ` for ${currentYearLimit.year}` : ""}: {
                    currentYearRemainingDays % 1 === 0 ? currentYearRemainingDays : currentYearRemainingDays.toFixed(2)
                  }
                  </FormHelperText>
                )}{
                nextYearLimit && nextYearRemainingDays !== undefined && (
                  <FormHelperText>
                    Remaining {logTypeLabel} days for {nextYearLimit.year}: {
                    nextYearRemainingDays % 1 === 0 ? nextYearRemainingDays : nextYearRemainingDays.toFixed(2)
                  }
                  </FormHelperText>
                )}
                <div className="flex gap-4">
                  <Field
                    name="startTime"
                    label="From *"
                    component={DateTimePickerControl}
                    validate={(val) =>
                      customDateValidator(exceptionDaysData, borderDate, val)
                    }
                    variant="date"
                    disabled={isEditForm}
                    shouldDisableDate={shouldDisableDate}
                    dataCy="day-off-from-date"
                  />
                  <Field
                    name="endTime"
                    label="To *"
                    component={DateTimePickerControl}
                    validate={(val) =>
                      customDateValidator(exceptionDaysData, borderDate, val)
                    }
                    variant="date"
                    disabled={isEditForm}
                    shouldDisableDate={shouldDisableDate}
                    dataCy="day-off-to-date"
                  />
                </div>
                <Field
                  name="description"
                  label={`Description${
                    values.logType === "other_reason" ? " *" : ""
                  }`}
                  multiline
                  rows={3}
                  component={TextControl}
                  dataCy="day-off-description"
                />
              </div>
              <div
                className={`flex gap-2 ${
                  isEditForm ? "justify-between" : "justify-end"
                }`}
              >
                {isEditForm && (
                  <Button
                    variant="secondary"
                    aria-label="delete absence"
                    className="flex min-w-18 items-center justify-center"
                    onClick={() => {
                      setSubmitFuncType(SUBMIT_FUNC_TYPE.DELETE);
                      modalRef.current?.openModal();
                    }}
                    data-cy="day-off-delete-button"
                    disabled={isDeleteTimeSheetLoading || submitting}
                  >
                    {isDeleteTimeSheetLoading ? (
                      <LoadingIcon className="animate-spin" />
                    ) : (
                      "Delete"
                    )}
                  </Button>
                )}
                <div className="flex gap-2">
                  <Button
                    variant="secondary"
                    onClick={handleModalClose}
                    data-cy="day-off-cancel-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="flex min-w-18 items-center justify-center"
                    disabled={submitting || pristine || hasValidationErrors}
                    data-cy="day-off-submit-button"
                    onClick={() => {
                      setSubmitFuncType(SUBMIT_FUNC_TYPE.SAVE);
                      modalRef.current?.openModal();
                    }}
                  >
                    {isUpsertDayOffLoading ? (
                      <LoadingIcon className="animate-spin"/>
                    ) : isEditForm ? (
                      "Save"
                    ) : (
                      "Add"
                    )}
                  </Button>
                </div>
              </div>
              <Modal
                ref={modalRef}
                onSubmit={() => {
                  modalRef.current?.closeModal();
                  submitFunc[submitFuncType]();
                }}
                contentMessage={MODAL_MESSAGE[submitFuncType]}
              />
            </form>
          );
        }}
      </Form>
    </Drawer>
  );
};
