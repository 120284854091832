import axios, { AxiosError } from "axios";
import API from "config/api";
import { useAuthContext } from "contexts";
import { useCallback } from "react";
import { toast } from "react-toastify";
import useSWRMutation, { MutationFetcher } from "swr/mutation";
import { ProjectDocument } from "types/project";

export type GetProjectDocumentsRequestParams = {
  projectId: string;
};

export type GetProjectDocumentsRequestArgs = {
  requestQueryParams: GetProjectDocumentsRequestParams;
  requestBody?: {
    format?: "json";
  };
};


export const useGetProjectDocuments = () => {
  const { token } = useAuthContext();
  const getProjectDocuments = useCallback<
    MutationFetcher<ProjectDocument[], string, GetProjectDocumentsRequestArgs>
  >(
    async (
      url,
      {
        arg: {
          requestQueryParams: { projectId },
        },
      }
    ) => {
      try {
        const { data } = await axios.get<ProjectDocument[]>(
          `${url}/${projectId}`,
          {
            headers: {
              "Authorization": token ? `Bearer ${token}` : "",
              "Content-Type": "application/json",
            },
            transformResponse: (response) => {
              const data: ProjectDocument[] =
                JSON.parse(response || "");
              return data;
            },
          }
        );
        return data;
      } catch (e) {
        toast.error("Something went wrong.");
        throw new Error((e as AxiosError)?.message);
      }
    },
    [token]
  );

  return useSWRMutation(
    `${process.env.REACT_APP_BACKEND_URL}${API.projectDocuments}`,
    getProjectDocuments,
    { throwOnError: false }
  );
};
