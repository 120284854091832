import { gql, useQuery } from "@apollo/client";

export type EmployeeAbsenceLimit = {
  limit: number;
  logType: string;
  unaccountedAbsences: number;
  year: number;
  totalLimit: number;
  approvedAbsences: number;
};

export type GetAbsencesInfoData = {
  employee_absence_limits: EmployeeAbsenceLimit[];
};

export const GET_ABSENCES_INFO = gql`
  query getMyAbsencesInfo {
    employee_absence_limits {
     limit
     logType: log_type
     unaccountedAbsences: unaccounted_absences
     year
     totalLimit: total_limit
     approvedAbsences: approved_absences
    }
  }
`;

export const useGetAbsencesInfo = () =>
  useQuery<GetAbsencesInfoData>(
    GET_ABSENCES_INFO
  );
