import { EmployeeAbsenceLimit } from "hooks";

export const calculateRemainingDays = (
  yearLimit: EmployeeAbsenceLimit
) => {
  let remainingDays = yearLimit ? yearLimit?.limit : 0; // case if data is synchronized with optima
  if (yearLimit && yearLimit?.unaccountedAbsences) { // case if data is not synchronized with optima
    if (yearLimit?.totalLimit) {
      remainingDays = yearLimit?.totalLimit - yearLimit.approvedAbsences;  // if year limit info exists
    } else {
      remainingDays = yearLimit?.limit - yearLimit.unaccountedAbsences;  // if year limit info not exists
    }
  }
  return remainingDays
};
