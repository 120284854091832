import { USER_ROLES_ENUM } from "config/constants";
import {
  AccountingIcon,
  CalendarIcon,
  DocumentIcon,
  EmployeesIcon,
  EventIcon,
  HelpIcon,
  HomeIcon,
  ScienceIcon,
} from "resources/icons";
import ROUTES from "./routes";

export interface View {
  name: string;
  path: string;
  hideNavigation: boolean;
  sidebarVisible: boolean;
  breadcrumbs?: {
    visible: boolean;
    detailsItem?: {
      visible?: boolean;
      withDropdown?: boolean;
      path?: string;
      withSearchParam?: boolean;
    };
  };
  icon?: any;
}

export type EmployeeViews =
  // | "dashboard"
  | "careerPathDetails"
  | "careerPathForm"
  | "timesheetsDetails"
  // | "skillsAssessment"
  | "documents"
  | "events"
  | "serviceDesk";

export type ManagerViews =
  | "addOrEditEmployee"
  | "employees"
  | "employee"
  | "projects"
  | "project"
  | "projectReport"
  | "assignments"
  | "careerPath"
  | "careerPathForm"
  | "careerPathDetails"
  | "timesheets"
  | "timesheetsDetails"
  | "accounting"
  | "documents"
  // | "documentSubcategory"
  | "events"
  | "serviceDesk"
  | "processes";

type Views = {
  [USER_ROLES_ENUM.EMPLOYEE]: Record<EmployeeViews, View>;
  [USER_ROLES_ENUM.MANAGER]: Record<ManagerViews, View>;
  common: Record<string, View>;
};

export const VIEWS: Views = {
  [USER_ROLES_ENUM.EMPLOYEE]: {
    // dashboard: {
    //   name: "Dashboard",
    //   path: ROUTES.employee.dashboard,
    //   sidebarVisible: true,
    //   hideNavigation: false,
    //   icon: HomeIcon
    // },
    timesheetsDetails: {
      name: "Timesheets",
      path: ROUTES.employee.timesheetsDetails,
      sidebarVisible: true,
      icon: CalendarIcon,
      hideNavigation: false,
      breadcrumbs: { visible: true },
    },
    careerPathDetails: {
      name: "Talk2Grow",
      path: ROUTES.employee.careerPathDetails,
      sidebarVisible: true,
      hideNavigation: false,
      icon: ScienceIcon,
      breadcrumbs: { visible: true },
    },
    careerPathForm: {
      name: "Talk2Grow Form",
      path: ROUTES.employee.careerPathForm,
      sidebarVisible: false,
      hideNavigation: true,
    },
    // skillsAssessment: {
    //   name: "Skills Assessment",
    //   path: ROUTES.employee.skillsAssessment,
    //   sidebarVisible: true,
    //   hideNavigation: false,
    //   icon: HomeIcon,
    //   breadcrumbs: { visible: true },
    // },
    documents: {
      name: "Documents",
      path: ROUTES.employee.documents,
      sidebarVisible: true,
      hideNavigation: false,
      icon: DocumentIcon,
    },
    events: {
      name: "Events",
      path: ROUTES.employee.events,
      sidebarVisible: true,
      hideNavigation: false,
      icon: EventIcon,
    },
    serviceDesk: {
      name: "Service Desk",
      path: ROUTES.employee.serviceDesk,
      sidebarVisible: true,
      hideNavigation: false,
      icon: HelpIcon,
    },
    // serviceDesk: {
    //   name: "Service Desk",
    //   path: ROUTES.employee.serviceDesk,
    //   sidebarVisible: true, // Re-enable whenever it is decided to publish SD
    //   hideNavigation: false,
    //   icon: HelpIcon,
    //   breadcrumbs: { visible: true },
    // },
  },
  [USER_ROLES_ENUM.MANAGER]: {
    timesheets: {
      name: "Timesheets",
      path: ROUTES.manager.timesheets,
      sidebarVisible: true,
      icon: CalendarIcon,
      hideNavigation: false,
      breadcrumbs: {
        visible: true,
        detailsItem: {
          path: ROUTES.manager.timesheetsDetails,
          withDropdown: true,
          withSearchParam: true,
        },
      },
    },
    timesheetsDetails: {
      name: "Timesheets",
      path: ROUTES.manager.timesheetsDetails,
      sidebarVisible: false,
      hideNavigation: false,
      breadcrumbs: {
        visible: true,
        detailsItem: {
          path: ROUTES.manager.timesheetsDetails,
          withDropdown: true,
          withSearchParam: true,
        },
      },
    },
    careerPathDetails: {
      name: "Talk2Grow",
      path: ROUTES.manager.careerPathDetails,
      sidebarVisible: false,
      hideNavigation: false,
      breadcrumbs: {
        visible: true,
        detailsItem: { visible: true },
      },
    },
    careerPathForm: {
      name: "Talk2Grow Form",
      path: ROUTES.manager.careerPathForm,
      sidebarVisible: false,
      hideNavigation: false,
    },
    careerPath: {
      name: "Talk2Grow",
      path: ROUTES.manager.careerPath,
      sidebarVisible: true,
      icon: ScienceIcon,
      hideNavigation: false,
      breadcrumbs: { visible: true },
    },
    accounting: {
      name: "Accounting",
      path: ROUTES.manager.accounting,
      sidebarVisible: true,
      icon: AccountingIcon,
      hideNavigation: false,
      breadcrumbs: { visible: true },
    },
    employees: {
      name: "Employees",
      path: ROUTES.manager.employees,
      sidebarVisible: true,
      icon: EmployeesIcon,
      hideNavigation: false,
      breadcrumbs: { visible: true },
    },
    addOrEditEmployee: {
      name: "Add or Edit Employee",
      path: ROUTES.manager.addOrEditEmployee,
      sidebarVisible: false,
      hideNavigation: false,
      breadcrumbs: {
        visible: true,
      },
    },
    employee: {
      name: "Employee",
      path: ROUTES.manager.employee,
      sidebarVisible: false,
      hideNavigation: false,
      breadcrumbs: {
        visible: true,
        detailsItem: { visible: true },
      },
    },
    projects: {
      name: "Projects",
      path: ROUTES.manager.projects,
      sidebarVisible: true,
      // TODO temporary icon
      icon: HomeIcon,
      hideNavigation: false,
      breadcrumbs: {
        visible: true,
      },
    },
    project: {
      name: "Project",
      path: ROUTES.manager.project,
      sidebarVisible: false,
      hideNavigation: false,
      breadcrumbs: {
        visible: true,
        detailsItem: { visible: true },
      },
    },
    projectReport: {
      name: "Project report",
      path: ROUTES.manager.projectReport,
      sidebarVisible: false,
      hideNavigation: false,
      breadcrumbs: { visible: true },
    },
    assignments: {
      name: "Assignments",
      path: ROUTES.manager.assignments,
      sidebarVisible: true,
      icon: HomeIcon,
      hideNavigation: false,
      breadcrumbs: {
        visible: true,
      },
    },
    processes: {
      name: "Processes",
      path: ROUTES.manager.processes,
      sidebarVisible: true,
      hideNavigation: false,
      icon: HomeIcon,
      breadcrumbs: { visible: true },
    },
    documents: {
      name: "Documents",
      path: ROUTES.manager.documents,
      sidebarVisible: true,
      hideNavigation: false,
      icon: DocumentIcon,
    },
    // documentSubcategory: {
    //   name: "Documents",
    //   path: ROUTES.manager.documentSubcategory,
    //   sidebarVisible: false,
    //   hideNavigation: false,
    //   breadcrumbs: { visible: false },
    // },
    events: {
      name: "Events",
      path: ROUTES.manager.events,
      sidebarVisible: true,
      hideNavigation: false,
      icon: EventIcon,
    },
    serviceDesk: {
      name: "Service Desk",
      path: ROUTES.manager.serviceDesk,
      sidebarVisible: true,
      hideNavigation: false,
      icon: HelpIcon,
    },
  },
  common: {
    sessionExpired: {
      name: "Session Expired",
      path: ROUTES.common.sessionExpired,
      sidebarVisible: false,
      hideNavigation: true,
    },
    userNotFound: {
      name: "UserNotFound",
      path: ROUTES.common.userNotFound,
      sidebarVisible: false,
      hideNavigation: true,
    },
    managerLogin: {
      name: "Manager Login",
      path: ROUTES.common.managerLogin,
      sidebarVisible: false,
      hideNavigation: true,
    },
  },
};
