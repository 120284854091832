import { TabType } from "components";

// CareerPathPage
export enum TALK_2_GROW_TAB {
  SURVEYS_IN_PROGRESS = "surveys_in_progress",
  SURVEYS_DONE = "surveys_done",
}

export enum TALK_2_GROW_TAB_LABEL {
  SURVEYS_IN_PROGRESS = "In progress",
  SURVEYS_DONE = "Done",
}

export const TALK_2_GROW_TABS: TabType<TALK_2_GROW_TAB>[] = [
  {
    key: TALK_2_GROW_TAB.SURVEYS_IN_PROGRESS,
    label: "In progress",
  },
  {
    key: TALK_2_GROW_TAB.SURVEYS_DONE,
    label: "Done",
  },
];

// CareerPathDetailsPage
export enum TALK_2_GROW_DETAILS_TAB {
  COMPETENCIES = "competencies",
  EVALUATION_SURVEY_PREVIEW = "survey_results",
  OKR = "okr",
}

export const TALK_2_GROW_DETAILS_TABS: TabType<TALK_2_GROW_DETAILS_TAB>[] = [
  {
    key: TALK_2_GROW_DETAILS_TAB.COMPETENCIES,
    label: "Competencies",
  },
  {
    key: TALK_2_GROW_DETAILS_TAB.EVALUATION_SURVEY_PREVIEW,
    label: "Survey results",
  },
  {
    key: TALK_2_GROW_DETAILS_TAB.OKR,
    label: "Development path (OKR)",
  },
];

// EmployeesPage
export enum EMPLOYEE_STATUS_TAB {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export const EMPLOYEE_STATUS_TABS: TabType<EMPLOYEE_STATUS_TAB>[] = [
  { key: EMPLOYEE_STATUS_TAB.ACTIVE, label: "Active" },
  { key: EMPLOYEE_STATUS_TAB.ARCHIVED, label: "Archived" },
];

// EmployeePage
export enum EMPLOYEE_TAB {
  SUMMARY = "SUMMARY",
  ASSIGNMENTS = "ASSIGNMENTS",
  CONTRACTS = "CONTRACTS",
  DOCUMENTS = "DOCUMENTS"
}

export const EMPLOYEE_TABS: TabType<EMPLOYEE_TAB>[] = [
  { key: EMPLOYEE_TAB.SUMMARY, label: "Summary" },
  { key: EMPLOYEE_TAB.ASSIGNMENTS, label: "Assignments" },
  { key: EMPLOYEE_TAB.CONTRACTS, label: "Contracts" },
  { key: EMPLOYEE_TAB.DOCUMENTS, label: "Documents" },
];

// ProjectsPage
export enum PROJECT_STATUS_TAB {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export const PROJECT_STATUS_TABS: TabType<PROJECT_STATUS_TAB>[] = [
  { key: PROJECT_STATUS_TAB.ACTIVE, label: "Active" },
  { key: PROJECT_STATUS_TAB.ARCHIVED, label: "Archived" },
];

// ProjectPage
export enum PROJECT_TAB {
  SUMMARY = "summary",
  TIMESHEETS = "timesheets",
  DOCUMENTS = "documents",
}

export const PROJECT_TABS: TabType<PROJECT_TAB>[] = [
  { key: PROJECT_TAB.SUMMARY, label: "Summary" },
  { key: PROJECT_TAB.TIMESHEETS, label: "Timesheets" },
  { key: PROJECT_TAB.DOCUMENTS, label: "Documents" },
];

// Notifications sidebar
export enum NOTIFICATIONS_TAB {
  UNREAD = "unread",
  ALL = "all",
}

export const NOTIFICATIONS_TABS: TabType<NOTIFICATIONS_TAB>[] = [
  { key: NOTIFICATIONS_TAB.UNREAD, label: "Unread" },
  { key: NOTIFICATIONS_TAB.ALL, label: "All" },
];

// ServiceDeskPage
export enum SERVICE_DESK_TAB {
  MY_OPEN_REQUESTS = "my_open_requests",
  MY_CLOSED_REQUESTS = "my_closed_requests",
  TO_APPROVE = "to_approve",
}

export const SERVICE_DESK_TABS: TabType<SERVICE_DESK_TAB>[] = [
  { key: SERVICE_DESK_TAB.MY_OPEN_REQUESTS, label: "My open requests" },
  { key: SERVICE_DESK_TAB.MY_CLOSED_REQUESTS, label: "My closed requests" },
  { key: SERVICE_DESK_TAB.TO_APPROVE, label: "Requests to approve" },
];

export enum TICKET_DETAILS_TAB {
  DETAILS = "DETAILS",
  HISTORY = "HISTORY",
}

export const TICKET_DETAILS_TABS: TabType<TICKET_DETAILS_TAB>[] = [
  { key: TICKET_DETAILS_TAB.DETAILS, label: "Details" },
  { key: TICKET_DETAILS_TAB.HISTORY, label: "History" },
];
